const w = typeof window === `undefined` ? undefined : window

if (w) window.runWS = (token = `cMm3sXyFv56RYQq81BS79KXwiWFC1-FgPqAEEHd9_Ut`) => {
  const ws = new WebSocket( `wss://chat3.instytutkryptografii.pl/websocket` )
  const wait = ms => new Promise( r => setTimeout( r, ms ) )

  ws.emit = data => ws.send( JSON.stringify( data )  )

  ws.onopen = async() => {
    console.log( `%cOpened`, `color:lime; font-weight:bold` )

    ws.emit({
      "msg": `connect`,
      "version": `1`,
      "support": [ `1` ],
    })

    await wait( 200 )

    ws.emit({
      "msg": `method`,
      "method": `login`,
      "id": `42`,
      "params": [ { "resume":token } ],
    })
  }

  ws.onmessage = message => {
    const data = JSON.parse( message.data )
    if (data.id === `42`) console.log( `%cAuth data`, `color:coral`, data )
    else console.log( data )
  }

  window.ws = ws
}

if (w) window.runHTTP = (token = `cMm3sXyFv56RYQq81BS79KXwiWFC1-FgPqAEEHd9_Ut`, userId = `tLd8wvNHpSkaGxdRK`) => {
  return fetch( `https://chat3.instytutkryptografii.pl/api/v1/users.info`, {
    headers: {
      "X-Auth-Token": token,
      "X-User-Id": userId,
      "Content-type": `application/json`,
    },
  } ).then( r => r.json() )
}
