// import { Link } from "gatsby"
import * as React from "react"

import "../styles/normalize.css"
import "../styles/main.css"
// import List, { ListItem } from "../components/list/List"
// import usePaginatedArray from "../hooks/usePaginatedArray"

import Chat from "../components/chat/Chat"
import "../functions/runWs"

export default function IndexPage() {
  // const { loadMore:loaderTrigger, ...rest } = usePaginatedArray()
  // const items = rest.items.reverse().map( i => <ListItem key={i.key}>{i.value}</ListItem> )
  if (typeof window !== `undefined`) {
    console.log( `Uruchamiam tu za Ciebie funkcję "runWS(). Jest` )
    console.log( `Jeśli zobaczysz dane autoryzacyjne, to użyj ich następnie przy wywoływaniu "runHTTP(token, id).` )
    console.log( `Jednak najprawdopodobniej token którego używam bęzie przedawniony, więc musisz wygenerować nowy zgodnie z instrukcją na stronie` )
    window.runWS()
  }

  return (
    <main className="maxAvailableHeight">
      {/* <Chat style={{ flex:1 }} name="chat" /> */}
      <section>
        <h2>WebSocket</h2>
        <p>
          Otwóz konsolę i uruchom funkcję "runWS" podając token użytkownika.<br />
          Token, znajduje się za endpointem <a href="view-source:https://instytutkryptografii.pl/auth/login_rocket">https://instytutkryptografii.pl/auth/login_rocket</a><br />
          Przykład użycia: <code>runWS( "cMm3sXyFv56RYQq81BS79KXwiWFC1-FgPqAEEHd9_Ut" )</code><br />
          W wyniku powinien wyświetlić się obiekt z danymi autoryzującymi (id_użytkownika, token).
        </p>
      </section>
      <section>
        <h2>HTTP</h2>
        <p>
          Otwórz konsolę i uruchom funkcję "runHTTP" podając token i opcjonalnie id użytkownika (sam token może nie zadziałać).<br />
          Token, znajduje się za endpointem <a href="view-source:https://instytutkryptografii.pl/auth/login_rocket">https://instytutkryptografii.pl/auth/login_rocket</a><br />
          ID użytkownika znajduje się w wyniku działania funkcji "runWS"<br />
          Przykład użycia: <span>runHTTP( "cMm3sXyFv56RYQq81BS79KXwiWFC1-FgPqAEEHd9_Ut" )</span><br />
          Przykład użycia: <span>runHTTP( "cMm3sXyFv56RYQq81BS79KXwiWFC1-FgPqAEEHd9_Ut", "tLd8wvNHpSkaGxdRK" )</span><br />
          W wyniku Nie powinno być żadnego błędu, który występuje i należy go rozwiązać<br />
        </p>
      </section>
    </main>
    // <main>
    //   Hello world<br />
    //   <Link to="/chat">Chat</Link>

  //   {/* <List style={{ height:500 }} loaderTrigger={loaderTrigger} {...rest} items={items} reversed /> */}
  // </main>
  )
}
